import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

function NavBar() {
    return (
        <nav className=" Nav bg-transparent px-5 py-2 static md:fixed">
            <div className="container flex flex-wrap items-center justify-between mx-auto">
                <a href="https://maikuro.ai" className="flex items-center">
                    <span className="self-center text-xl lg:text-2xl font-bold whitespace-nowrap">⚡️Maikuro</span>
                </a>
                <div className="block w-auto">
                    <ul className="p-4 flex-row space-x-8 mt-0 text-sm font-medium border-0">
                        <li>
                            <a href="https://twitter.com/joinmaikuro" rel="noopener noreferrer" target="_blank" className="block py-2 pl-3 bg-transparent text-[#1DA1F2]" aria-current="page">
                                <FontAwesomeIcon size='xl' icon={faTwitter} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;