import './App.css';
import NavBar from './NavBar';

function App() {
  return (
    <>
      <NavBar />
      <div className="App">
        <div className='max-w-[700px]'>
          <div className="">
            <h1 className='leading-tight -mt-10 sm:mt-0 md:mt-20 text-[55px] sm:md:text-[80px] lg:text-[100px]'>
              Collective Intelligence, Reimagined.
            </h1>
          </div>
          
          <div className="mt-5 sm:text-[1rem] md:text-[1.1rem] font-['Roboto']">
            We're building a new knowledge platform powered by fine-tuned GPT-based large language models specifically tailored for the Bitcoin and Lightning Network communities. Launching in Q1 2023.
          </div>
          
          <div className='mt-10'>
            <button 
              onClick={() => window.open("https://twitter.com/intent/follow?screen_name=joinmaikuro", "_blank")} 
              className="bg-[#ef8708] text-[white] pt-4 pb-4 pl-20 pr-20 rounded-full font-extrabold">
              Get Notified
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
